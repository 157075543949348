import { colors } from "@/style/colors";
import { mixScrollBox } from "@/style/mixIn";
import { ModalContent } from "@chakra-ui/react";
import styled from "styled-components";

export const CreateModalStyle = styled(ModalContent)`
  .chakra-modal__header {
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }

  ${mixScrollBox()}
`;
