import { auth, db } from "@/firebase";
import { createRandomNick } from "@/utils/randomNick";
import { get, ref, set } from "firebase/database";
import { signInWithPopup } from "firebase/auth";
import { provider } from "@/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { api } from "..";

interface UserInfo {
  uid: string;
  nick: string;
  date_regis: number;
}

const handleLogin = async (user: UserInfo) => {
  try {
    // 1. 사용자 존재 여부 확인
    const checkResult = await api.post("photo.php", {
      a: "checkUserExists",
      userUid: user.uid,
    });
    // 2. 사용자가 존재하지 않으면 setUserInfo 호출
    if (!checkResult.data.exists) {
      const setUserResult = await api.post("photo.php", {
        a: "setUserInfo",
        userUid: user.uid,
        nick: user.nick,
        regis_date: user.date_regis,
      });

      // 3. Firebase에 사용자 ID 업데이트
      if (setUserResult.data.id) {
        await set(ref(db, `users/${user.uid}/id`), setUserResult.data.id);
      }
    }
  } catch (error) {
    console.error("사용자 정보 확인/저장 중 오류 발생:", error);
  }
};

//구글 로그인
export const googleHandler = async (navigate: Function) => {
  try {
    provider.setCustomParameters({ prompt: "select_account" });
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const randomNick = createRandomNick();

    const userRef = ref(db, `users/${user.uid}`);
    const snapshot = await get(userRef);
    const userInfo = {
      uid: user.uid,
      nick: randomNick,
      date_regis: new Date().getTime(),
    };
    handleLogin(userInfo);
    if (snapshot) {
      navigate("/");
      return;
    }
    set(ref(db, `users/${user.uid}`), userInfo);
    navigate("/");
  } catch (error) {
    console.error("Error during Google sign-in:", error);
  }
};

//이메일 로그인
export const onEmailLogin = async (
  data: { email: string; password: string },
  navigate: Function
) => {
  try {
    const result = await signInWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
    const user = result.user;
    handleLogin({
      uid: user.uid,
      nick: "",
      date_regis: new Date().getTime(),
    });
    navigate("/");
  } catch (error) {
    console.error("Error during email sign-in:", error);
    alert("로그인에 실패했습니다. 이메일과 비밀번호를 확인해주세요.");
  }
};
