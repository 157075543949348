import { ProfileBoxStyle } from "./style";
import {
  Avatar,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useUserStore } from "../../store/useUserStore";
import ModifyProfileModal from "../modal/ModifyProfileModal";
import { CiMenuKebab } from "react-icons/ci";
import { logout } from "../../api/sigjnout";
import { IoIosClose } from "react-icons/io";

const ProfileBox = ({ onCloseMyPhoto }: { onCloseMyPhoto: () => void }) => {
  const {
    isOpen: isProfileOpen,
    onOpen: onOpenProfile,
    onClose: onCloseProfile,
  } = useDisclosure();
  const { getUser } = useUserStore();
  const userInfo = getUser();

  const handleLogout = async () => {
    try {
      await logout();
      // 로그아웃 성공 후 처리 (예: 홈 페이지로 리다이렉트)
    } catch (error) {
      // 에러 처리
    }
  };

  if (!userInfo) return null;
  return (
    <>
      <ProfileBoxStyle>
        <Flex align="center" gap={3}>
          <Avatar className="profile-image" src={userInfo.profileImage || ""} />
          <span className="nick">{userInfo.nick}</span>
          <Menu>
            <MenuButton
              as={IconButton}
              variant="ghost"
              className="menu-btn"
              icon={<CiMenuKebab />}
            ></MenuButton>
            <MenuList>
              <MenuItem onClick={onOpenProfile}>프로필 수정</MenuItem>
              <MenuItem onClick={handleLogout}>로그아웃</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
        <IoIosClose className="close-btn" onClick={onCloseMyPhoto} />
      </ProfileBoxStyle>
      <ModifyProfileModal
        userInfo={userInfo}
        isProfileOpen={isProfileOpen}
        onCloseProfile={onCloseProfile}
      />
    </>
  );
};

export default ProfileBox;
