import { useMutation } from "@tanstack/react-query";
import { api } from "..";

interface IUpdateUserInfo {
  nick: string;
  profileImage: string;
  userId: number;
}

export const updateUserInfo = async (data: IUpdateUserInfo) => {
  return await api.post("photo.php", {
    a: "updateUserInfo",
    ...data,
  });
};
export const useUpdateUserInfo = () => {
  return useMutation({
    mutationFn: (data: IUpdateUserInfo) => updateUserInfo(data),
  });
};
