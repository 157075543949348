import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

export const logout = async (): Promise<void> => {
  try {
    await signOut(auth);
    // 로그아웃 성공
  } catch (error) {
    console.error("로그아웃 중 오류 발생:", error);
    throw error;
  }
};
