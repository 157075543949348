import { colors } from "@/style/colors";
import styled from "styled-components";

export const ProfileBoxStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  .nick {
    font-weight: 600;
    font-size: 1.2rem;
  }
  .menu-btn {
    &:active,
    &[data-active],
    &:focus {
      background: transparent;
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
  }
  .close-btn {
    width: 2rem;
    height: 2rem;
    color: ${colors.BLACK_500};
`;
