import { MarkerData } from "@/types/marker";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface MarkerStore {
  marker: Partial<MarkerData>;
  markerList: MarkerData[];
  setMarker: (marker: MarkerData) => void;
  putMarkerList: (marker: MarkerData) => void;
  updateMarkerList: (markerList: MarkerData[]) => void;
  removeMarkerList: (uid: string) => void;
  clearMarker: () => void;
  clearMarkerList: () => void;
}

export const useMarkerStore = create<MarkerStore>()(
  devtools(
    (set) => ({
      marker: {},
      markerList: [],
      setMarker: (marker) => set({ marker }),
      putMarkerList: (marker) =>
        set((state) => ({ markerList: [...state.markerList, marker] })),
      updateMarkerList: (markerList) => set({ markerList }),
      removeMarkerList: (uid) =>
        set((state) => ({
          markerList: state.markerList.filter((marker) => marker.uid !== uid),
        })),
      clearMarker: () => set({ marker: {} }),
      clearMarkerList: () => set({ markerList: [] }),
    }),
    { name: "markerStore" }
  )
);
