import {
  Avatar,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { MdOutlineDateRange, MdOutlineFavoriteBorder } from "react-icons/md";
import { IoMdLink } from "react-icons/io";
import ModalMenu from "../ModalMenu";
import { useUserStore } from "../../../store/useUserStore";
import { useMarkerStore } from "../../../store/useMarkerStore";
import { PopupContainerStyle } from "./style";
import { BsBookmarkStar, BsBookmarkStarFill } from "react-icons/bs";
import { IconStyle } from "../../../style/common";
import { MarkerData } from "../../../types/marker";
import {
  handleBookmarkToggle,
  useBookmarkCountRealtime,
} from "@/api/marker/bookmark";
import { MdOutlineFavorite } from "react-icons/md";
import { handleLikeToggle, useLikeCountRealtime } from "@/api/marker/like";
import { PhotoDataType } from "@/api/myphoto/type";
interface MarkerPopupProps {
  data: Partial<PhotoDataType>;
  isOpen: boolean;
  onClose: () => void;
}

function MarkerPopup({ isOpen, onClose, data }: MarkerPopupProps) {
  const { getUser } = useUserStore();
  const userInfo = getUser();
  const setMarker = useMarkerStore((state: any) => state.setMarker);
  const onCloseModal = () => {
    setMarker({});
    onClose();
  };

  //북마크 카운트
  const { data: bookmarkCount } = useBookmarkCountRealtime(
    data.uid,
    data?.bookmark_count
  );

  //좋아요 카운트
  const { data: likeCount } = useLikeCountRealtime(data.uid, data.like_count);

  if (!userInfo) return null;
  return (
    <>
      <Modal isCentered isOpen={isOpen} onClose={onCloseModal} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{data.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <PopupContainerStyle>
              <div className="img-box">
                <img className="popup-image" src={data.image_url} alt="Image" />
              </div>
              <section className="content-box">
                {/* 반응하기 버튼 */}
                <div className="reaction-box">
                  {/* 좋아요 버튼 */}
                  <IconStyle
                    onClick={() =>
                      handleLikeToggle(Number(data.uid), data as MarkerData)
                    }
                  >
                    {userInfo.likes?.includes(Number(data.uid)) ? (
                      <MdOutlineFavorite />
                    ) : (
                      <MdOutlineFavoriteBorder />
                    )}
                    <span>{likeCount}</span>
                  </IconStyle>
                  {/* 북마크 버튼 */}
                  <IconStyle
                    onClick={() =>
                      handleBookmarkToggle(Number(data.uid), data as MarkerData)
                    }
                  >
                    {userInfo.book_mark?.includes(Number(data.uid)) ? (
                      <BsBookmarkStarFill />
                    ) : (
                      <BsBookmarkStar />
                    )}
                    <span>{bookmarkCount}</span>
                  </IconStyle>
                </div>
                <div className="top-info">
                  <div className="user">
                    <Avatar size="xs" src={data.profile_image} />
                    {data.nick}
                  </div>
                  {userInfo.id === Number(data.user_id) && (
                    <ModalMenu data={data} onCloseModal={onCloseModal} />
                  )}
                </div>
                <div className="info-list">
                  <div className="info-item">
                    <span className="info-key">
                      <MdOutlineDateRange />
                    </span>
                    <span className="info-value">{data.date}</span>
                  </div>
                  {data.link && (
                    <div className="info-item" style={{ display: "none" }}>
                      <span className="info-key">
                        <IoMdLink />
                      </span>
                      <span className="info-value link">
                        <a
                          href={data.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {data.link}
                        </a>
                      </span>
                    </div>
                  )}
                </div>
              </section>
            </PopupContainerStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MarkerPopup;
