import Map from "../../components/Map";

function index() {
  return (
    <>
      <Map />
    </>
  );
}

export default index;
