import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/react";

import { useMap } from "react-leaflet";
import { useDateStore } from "../../../store/useDateStore";
import { format } from "date-fns";
import ProfileBox from "../../profileBox";
import { PhotoListContainerStyle, PhotoListStyle } from "./style";
import {
  useGetBookmarkPhoto,
  useGetPhotoList,
} from "@/api/myphoto/useGetPhoto";
import { useEffect, useState } from "react";
import { BsBookmarkStar } from "react-icons/bs";
import { MdOutlinePhoto } from "react-icons/md";
import { TbCurrentLocation } from "react-icons/tb";
import useBoolean from "@/hooks/useBoolean";
import MarkerPopup from "../MarkerDetail/MarkerPopup";
import Loading from "@/components/Loading";
import { useInView } from "react-intersection-observer";
import { PhotoDataType } from "@/api/myphoto/type";
import { useTabDrag } from "@/hooks/useTabDrag";

function MyphotoModal({ userInfo, isMyPhotoOpen, onCloseMyPhoto, onDateSet }) {
  const setDate = useDateStore((state) => state.setDate);
  const map = useMap();

  //사진 리스트
  const [photoList, setPhotoList] = useState<PhotoDataType[]>([]);
  //북마크 리스트
  const [bookmarkPhotoList, setBookmarkPhotoList] = useState<PhotoDataType[]>(
    []
  );

  //내 사진 리스트 가져오기
  const {
    data: myPhotoData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetPhotoList(userInfo.id, 20);
  const { ref: moreRef, inView: myPhotoInView } = useInView({
    threshold: 0.5,
  });
  useEffect(() => {
    if (myPhotoInView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [myPhotoInView]);

  //마커 상세 팝업
  const isMarkerModal = useBoolean();

  //사진 선택
  const [selectMarker, setSelectMarker] = useState<PhotoDataType | null>(null);
  const onMarkerDetail = (data: PhotoDataType) => {
    setSelectMarker(data);
    isMarkerModal.onTrue();
  };

  //해당 마커 위치로 이동
  const onCurrentPosition = (data: PhotoDataType) => {
    map.setView([data.lat, data.lng], 18);
    onDateSet(format(new Date(data.date), "yyyy-MM"));
    setDate(format(new Date(data.date), "yyyy-MM"));
  };

  //즐겨찾기 가진 사진 리스트 가져오기
  const { ref: bookmarkMoreRef, inView: bookmarkInView } = useInView({
    threshold: 0.5,
  });
  const {
    data: bookmarkPhotoData,
    fetchNextPage: bookmarkFetchNextPage,
    hasNextPage: bookmarkHasNextPage,
    isFetchingNextPage: bookmarkIsFetchingNextPage,
  } = useGetBookmarkPhoto(userInfo.book_mark, 20);
  useEffect(() => {
    if (bookmarkInView && bookmarkHasNextPage && !bookmarkIsFetchingNextPage) {
      bookmarkFetchNextPage();
    }
  }, [bookmarkInView]);

  //내사진/저장한사진 탭
  const [tab, setTab] = useState("myPhoto");

  const { touchState, translateX, handlers } = useTabDrag({
    initialTab: tab,
    onTabChange: setTab,
  });

  useEffect(() => {
    if (tab === "myPhoto") {
      setPhotoList(myPhotoData?.pages.flatMap((page) => page.data.list));
    } else {
      setBookmarkPhotoList(
        bookmarkPhotoData?.pages.flatMap((page) => page.data.list)
      );
    }
  }, [tab, myPhotoData, bookmarkPhotoData]);

  return (
    <>
      <Modal
        isCentered
        isOpen={isMyPhotoOpen}
        onClose={onCloseMyPhoto}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <PhotoListContainerStyle>
              <ProfileBox onCloseMyPhoto={onCloseMyPhoto} />
              <ul className="photo-list-tab">
                <li
                  className={tab === "myPhoto" ? "active" : ""}
                  onClick={() => setTab("myPhoto")}
                >
                  <MdOutlinePhoto />내 사진
                </li>
                <li
                  className={tab === "savedPhoto" ? "active" : ""}
                  onClick={() => setTab("savedPhoto")}
                >
                  <BsBookmarkStar />
                  저장한 사진
                </li>
              </ul>
              <div className={`photo-list-box ${tab}`}>
                <div
                  className="move-box"
                  onTouchStart={handlers.touchTabMoveStart}
                  onTouchMove={handlers.touchTabMove}
                  onTouchEnd={handlers.touchTabEnd}
                  style={{
                    transform: touchState && `translateX(${translateX}%)`,
                    transition: "transform 0.3s ease",
                  }}
                >
                  {
                    <>
                      <PhotoListStyle className="my-photo-list">
                        {photoList?.map((el: PhotoDataType) => (
                          <li key={el.uid}>
                            <img
                              src={el.image_url}
                              onClick={() => onMarkerDetail(el)}
                            />
                            <div className="btn-box">
                              <button onClick={() => onCurrentPosition(el)}>
                                <TbCurrentLocation className="ic-location" />
                              </button>
                            </div>
                          </li>
                        ))}
                        {hasNextPage && !isFetchingNextPage && (
                          <li ref={moreRef} style={{ aspectRatio: "auto" }}>
                            <Loading size="sm" />
                          </li>
                        )}
                      </PhotoListStyle>
                      {!photoList && (
                        <div className="no-photo">저장한 사진이 없습니다.</div>
                      )}
                    </>
                  }
                  {
                    <>
                      <PhotoListStyle className="bookmark-photo-list">
                        {bookmarkPhotoList?.map((el) => (
                          <li key={el.uid}>
                            <img
                              src={el.image_url}
                              onClick={() => onMarkerDetail(el)}
                            />
                            <div className="btn-box">
                              <button onClick={() => onCurrentPosition(el)}>
                                <TbCurrentLocation className="ic-location" />
                              </button>
                            </div>
                          </li>
                        ))}
                        {bookmarkHasNextPage && !bookmarkIsFetchingNextPage && (
                          <li
                            ref={bookmarkMoreRef}
                            style={{ aspectRatio: "auto" }}
                          >
                            <Loading size="sm" />
                          </li>
                        )}
                        {!bookmarkPhotoList && (
                          <li className="no-photo">저장한 사진이 없습니다.</li>
                        )}
                      </PhotoListStyle>
                    </>
                  }
                </div>
              </div>
            </PhotoListContainerStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
      {selectMarker && (
        <MarkerPopup
          data={selectMarker}
          isOpen={isMarkerModal.boolean}
          onClose={() => isMarkerModal.onFalse()}
        />
      )}
    </>
  );
}

export default MyphotoModal;
