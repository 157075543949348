const colors = {
  BORDER: "#ddd",
  BLACK_100: "#111",
  BLACK_200: "#222",
  BLACK_300: "#333",
  BLACK_400: "#444",
  BLACK_500: "#555",
  BLACK_600: "#666",
  BLACK_700: "#777",
  BLACK_800: "#888",
  BLACK_900: "#999",
  BLUE_100: "#BEE3F8",
  BLUE_200: "#90CDF4",
  BLUE_300: "#63B3ED",
  BLUE_400: "#4299E1",
  BLUE_50: "#EBF8FF",
  BLUE_500: "#3182CE",
  BLUE_600: "#2B6CB0",
  BLUE_700: "#2C5282",
  BLUE_800: "#2A4365",
  BLUE_900: "#1A365D",
  CYAN_100: "#C4F1F9",
  CYAN_200: "#9DECF9",
  CYAN_300: "#76E4F7",
  CYAN_400: "#0BC5EA",
  CYAN_50: "#EDFDFD",
  CYAN_500: "#00B5D8",
  CYAN_600: "#00A3C4",
  CYAN_700: "#0987A0",
  CYAN_800: "#086F83",
  CYAN_900: "#065666",
  GRAY_100: "#EDF2F7",
  GRAY_200: "#E2E8F0",
  GRAY_300: "#CBD5E0",
  GRAY_400: "#A0AEC0",
  GRAY_50: "#F7FAFC",
  GRAY_500: "#718096",
  GRAY_600: "#4A5568",
  GRAY_700: "#2D3748",
  GRAY_800: "#1A202C",
  GRAY_900: "#171923",
  GREEN_100: "#C6F6D5",
  GREEN_200: "#9AE6B4",
  GREEN_300: "#68D391",
  GREEN_400: "#48BB78",
  GREEN_50: "#F0FFF4",
  GREEN_500: "#38A169",
  GREEN_600: "#2F855A",
  GREEN_700: "#276749",
  GREEN_800: "#22543D",
  GREEN_900: "#1C4532",
  ORANGE_100: "#FEEBC8",
  ORANGE_200: "#FBD38D",
  ORANGE_300: "#F6AD55",
  ORANGE_400: "#ED8936",
  ORANGE_50: "#FFFAF0",
  ORANGE_500: "#DD6B20",
  ORANGE_600: "#C05621",
  ORANGE_700: "#9C4221",
  ORANGE_800: "#7B341E",
  ORANGE_900: "#652B19",
  PINK_100: "#FED7E2",
  PINK_200: "#FBB6CE",
  PINK_300: "#F687B3",
  PINK_400: "#ED64A6",
  PINK_50: "#FFF5F7",
  PINK_500: "#D53F8C",
  PINK_600: "#B83280",
  PINK_700: "#97266D",
  PINK_800: "#702459",
  PINK_900: "#521B41",
  PURPLE_100: "#E9D8FD",
  PURPLE_200: "#D6BCFA",
  PURPLE_300: "#B794F4",
  PURPLE_400: "#9F7AEA",
  PURPLE_50: "#FAF5FF",
  PURPLE_500: "#805AD5",
  PURPLE_600: "#6B46C1",
  PURPLE_700: "#553C9A",
  PURPLE_800: "#44337A",
  PURPLE_900: "#322659",
  RED_100: "#FED7D7",
  RED_200: "#FEB2B2",
  RED_300: "#FC8181",
  RED_400: "#F56565",
  RED_50: "#FFF5F5",
  RED_500: "#E53E3E",
  RED_600: "#C53030",
  RED_700: "#9B2C2C",
  RED_800: "#822727",
  RED_900: "#63171B",
  TEAL_100: "#B2F5EA",
  TEAL_200: "#81E6D9",
  TEAL_300: "#4FD1C5",
  TEAL_400: "#38B2AC",
  TEAL_50: "#E6FFFA",
  TEAL_500: "#319795",
  TEAL_600: "#2C7A7B",
  TEAL_700: "#285E61",
  TEAL_800: "#234E52",
  TEAL_900: "#1D4044",
  YELLOW_100: "#FEFCBF",
  YELLOW_200: "#FAF089",
  YELLOW_300: "#F6E05E",
  YELLOW_400: "#ECC94B",
  YELLOW_50: "#FFFFF0",
  YELLOW_500: "#D69E2E",
  YELLOW_600: "#B7791F",
  YELLOW_700: "#975A16",
  YELLOW_800: "#744210",
  YELLOW_900: "#5F370E",

  MAIN: "#553C9A",
};

export { colors };
