import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { equalCheck } from "../../utils/commonFunc";
import { useState } from "react";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref as sRef, uploadBytes } from "firebase/storage";
import { ref, update } from "firebase/database";
import ProfileImageUpload, {
  dataURLtoFile,
} from "../profileBox/ProfileImageUpload";
import { useUpdateUserInfo } from "@/api/auth/updateUserInfo";

function ModifyProfileModal({ userInfo, isProfileOpen, onCloseProfile }) {
  const { mutate: updateUserInfo } = useUpdateUserInfo();
  const toast = useToast();

  const [clipImg, setClipImg] = useState([]); //이미지

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm();

  //이미지 업로드
  const onUpdateImage = async (base64) => {
    if (!base64) return null;

    const fileName = `profile_${userInfo.uid}`;
    let file = dataURLtoFile(base64, fileName);
    const storageRef = sRef(storage, `images/${userInfo.uid}/profile`);
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("이미지 업로드 중 오류가 발생했습니다:", error);
      throw error; // 오류를 호출자에게 전파합니다.
    }
  };

  const onSubmit = async (values) => {
    const check = equalCheck(values, userInfo); //변경사항 체크
    if (check && clipImg.length === 0) {
      onCloseProfile();
      return;
    } //변경없음

    // 이미지가 변경된 경우에만 새 이미지 URL을 가져옴
    const imageUrl =
      clipImg.length > 0
        ? await onUpdateImage(clipImg[0])
        : userInfo.profileImage;

    update(ref(db, `users/${userInfo.uid}`), {
      nick: values.nick,
      profileImage: imageUrl || "",
    });

    updateUserInfo({
      nick: values.nick,
      profileImage: imageUrl || "",
      userId: userInfo.id,
    });

    toast({
      description: "수정되었습니다",
      status: "success",
      duration: 1000,
      isClosable: false,
    });
    onCloseProfile();
  };
  return (
    <Modal isCentered isOpen={isProfileOpen} onClose={onCloseProfile} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              <FormLabel fontSize="md" fontWeight={600}>
                이메일
              </FormLabel>
              <Input
                fontSize="sm"
                {...register("email", {
                  required: "이메일은 필수항목 입니다.",
                })}
                defaultValue={userInfo.email}
                placeholder="이메일"
                readOnly
              />
              <FormErrorMessage>
                {errors.nick?.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontSize="md" fontWeight={600}>
                프로필 이미지
              </FormLabel>
              <ProfileImageUpload clipImg={clipImg} setClipImg={setClipImg} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel fontSize="md" fontWeight={600}>
                닉네임
              </FormLabel>
              <Input
                fontSize="sm"
                {...register("nick", {
                  required: "닉네임은 필수항목 입니다.",
                })}
                defaultValue={userInfo.nick}
                placeholder="닉네임"
              />
              <FormErrorMessage>
                {errors.nick?.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <Flex mt={5}>
              <Button
                width="70%"
                colorScheme="blue"
                mr={3}
                type="submit"
                isLoading={isSubmitting}
              >
                수정하기
              </Button>
              <Button width="30%" onClick={onCloseProfile}>
                취소
              </Button>
            </Flex>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ModifyProfileModal;
