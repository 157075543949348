// 한국 영역을 폴리곤으로 정의
export const KOREA_POLYGON = [
  // 본토
  [
    [38.634, 125.7], // 북서
    [38.634, 129.5833], // 북동
    [37.0, 131.0], // 독도 포함
    [35.0, 130.0], // 대한해협
    [33.1, 126.1], // 제주 서쪽
    [33.1, 127.1], // 제주 동쪽
    [34.3, 126.1], // 서남해안
    [35.5, 125.0], // 서해중부
    [37.5, 124.5], // 서해북부
  ],
  // 제주도
  [
    [33.6, 126.15],
    [33.6, 126.95],
    [33.2, 126.95],
    [33.2, 126.15],
  ],
];
