import { useEffect, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import { useMap } from "react-leaflet/hooks";
import L from "leaflet";

const LocationMarker = () => {
  const map = useMap();
  const [currentPosition, setCurrentPosition] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(13);

  // divIcon 생성
  const customDivIcon = L.divIcon({
    className: "custom-div-icon", // CSS 클래스 이름
    html: '<div class="my-custom-pin"><div></div></div>', // HTML 내용
    iconSize: [30, 42], // 아이콘 크기
    iconAnchor: [15, 42], // 아이콘의 앵커 포인트
    popupAnchor: [0, -42], // 팝업의 앵커 포인트
  });

  const handleLocationFound = (e) => {
    setCurrentPosition(e.latlng);
    map.setView(e.latlng, map.getZoom());
  };

  useEffect(() => {
    // 줌기 줌 레벨 설정
    setCurrentZoom(map.getZoom());

    // 줌 레벨 변경 감지
    map.on("zoomend", () => {
      setCurrentZoom(map.getZoom());
    });

    map.locate().on("locationfound", handleLocationFound);

    return () => {
      map.off("zoomend");
    };
  }, [map]);

  // 줌 레벨이 13 미만일 때는 마커를 표시하지 않음
  return currentPosition === null || currentZoom < 13 ? null : (
    <Marker position={currentPosition} icon={customDivIcon}></Marker>
  );
};

export default LocationMarker;
