export const firebaseConfig = {
  apiKey: "AIzaSyBggS3wqPyfV13WPAiazJZBqAdHwObjUyM",
  authDomain: "photo-spot-b5900.firebaseapp.com",
  databaseURL:
    "https://photo-spot-b5900-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "photo-spot-b5900",
  storageBucket: "photo-spot-b5900.appspot.com",
  messagingSenderId: "911872679578",
  appId: "1:911872679578:web:140b92c3a18ec3fc914923",
  measurementId: "G-DWD5E6D1TC",
};
