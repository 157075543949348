import { KOREA_POLYGON } from "@/constant/map";

// 점이 폴리곤 내부에 있는지 확인하는 함수
export const isPointInPolygon = (point, polygon) => {
  const x = point[1]; // longitude
  const y = point[0]; // latitude
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][1],
      yi = polygon[i][0];
    const xj = polygon[j][1],
      yj = polygon[j][0];

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

// 중심 좌표가 한국 영역 내에 있는지 확인하는 함수 수정
export const isInKorea = (lat: number, lng: number) => {
  const point = [lat, lng];
  // 어느 하나의 폴리곤이라도 포함하면 true 반환
  return KOREA_POLYGON.some((polygon) => isPointInPolygon(point, polygon));
};
