import { create } from "zustand";

type RefetchStore = {
  locationListRefetch: (() => void) | null;
  setLocationListRefetch: (refetch: () => void) => void;
};

export const useRefetchStore = create<RefetchStore>((set) => ({
  locationListRefetch: null,
  setLocationListRefetch: (refetch) => set({ locationListRefetch: refetch }),
}));
