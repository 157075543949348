import { useState } from "react";
import { Marker, useMapEvent } from "react-leaflet";
import { Button, useDisclosure } from "@chakra-ui/react";
import short from "short-uuid";
import CreateModal from "./modal/createModal";
import { CenterMarkerStyle } from "../style/common";
import ic_location from "../assets/ic_location.svg";
import styled from "styled-components";
import { mixColFlex, mixFlex } from "@/style/mixIn";

const BtnBoxStyle = styled.div`
  position: fixed;
  width: 100%;
  max-width: 500px;
  ${mixColFlex("center", "stretch")};
  gap: 0.5rem;
  bottom: 2rem;
  left: 50%;
  padding: 0 0.8rem;
  transform: translateX(-50%);
  z-index: 500;
`;

const NewMarker = ({ setSaveMode, setRender, onSaveMode }) => {
  const { isOpen, onOpen, onClose } = useDisclosure(); //위치저장 모달

  const [newMarker, setNewMarker] = useState();

  //맵 중앙 좌표
  const [center, setCenter] = useState();

  //맵 이동중 여부
  const [isMove, setIsMove] = useState<boolean>(false);
  useMapEvent("move", (e) => {
    const center = e.target.getCenter(); // 맵의 중앙 좌표 얻기
    setCenter(center);
    setIsMove(true);
    setIsEnd(false);
  });

  const [isEnd, setIsEnd] = useState(false);
  useMapEvent("moveend", (e) => {
    const center = e.target.getCenter(); // 맵의 중앙 좌표 얻기
    setCenter(center);
    setIsMove(false);
    setIsEnd(true);
    const marker = {
      id: short.generate(),
      latitude: center.lat,
      longitude: center.lng,
    };
    setNewMarker(marker);
  });

  return (
    <>
      <CenterMarkerStyle className={isMove ? "move" : ""}>
        <img className={isEnd ? "end" : ""} src={ic_location} alt="location" />
        <span className="shadow"></span>
      </CenterMarkerStyle>
      {newMarker && (
        <>
          <CreateModal
            setRender={setRender}
            setSaveMode={setSaveMode}
            isOpen={isOpen}
            data={newMarker}
            onClose={onClose}
            newMarker={newMarker}
            setNewMarker={setNewMarker}
          />
        </>
      )}
      <BtnBoxStyle>
        <Button
          onClick={onOpen}
          size={"lg"}
          colorScheme={"purple"}
          className="confirm-btn"
          isDisabled={!center}
        >
          선택한 위치로 저장
        </Button>
        <Button onClick={onSaveMode} size={"lg"}>
          취소
        </Button>
      </BtnBoxStyle>
    </>
  );
};

export default NewMarker;
