import { mixFlex } from "@/style/mixIn";
import styled from "styled-components";

export const AppInstallStyle = styled.div`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 1000;
  button {
    ${mixFlex()};
    gap: 0.5rem;
    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
