import { colors } from "@/style/colors";
import { mixColFlex, mixFlex, mixScrollBox } from "@/style/mixIn";
import styled from "styled-components";

export const PhotoListContainerStyle = styled.section`
  padding: 1.25rem 0;
  ${mixColFlex()};
  align-items: stretch;
  gap: 1rem;
  .photo-list-tab {
    ${mixFlex()};
    gap: 0.5rem;
    li {
      cursor: pointer;
      width: 100%;
      height: 2.5rem;
      ${mixFlex()};
      gap: 0.25rem;
      border-radius: 0.5rem;
      background-color: ${colors.PURPLE_100};
      color: #fff;
      transition: all 0.2s ease;
      &.active {
        background-color: ${colors.PURPLE_600};
      }
    }
  }
  .no-photo {
    ${mixFlex()};
    width: 100%;
    height: 5rem;
    color: ${colors.GRAY_400};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .photo-list-box {
    height: calc(100vh - 18rem);
    max-height: 700px;
    position: relative;
    overflow: hidden;
    &.savedPhoto {
      .move-box {
        transform: translateX(-100%);
      }
    }
    .move-box {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all 0.2s ease;
      transform: translateX(0);
    }
    ul {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      &.bookmark-photo-list {
        left: 100%;
      }
    }
  }
`;

export const PhotoListStyle = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
  min-height: 10rem;
  ${mixScrollBox("calc(100vh - 18rem)", "0")};
  li {
    cursor: pointer;
    position: relative;
    ${mixFlex()};
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
    .btn-box {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 3rem;
      padding: 0.5rem;
      ${mixFlex("flex-start", "flex-end")};
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
      .ic-location {
        font-size: 1.5rem;
        color: #fff;
        opacity: 0.8;
      }
    }
  }
`;
