import { ref, onValue, off, runTransaction, set, get } from "firebase/database";
import { db } from "@/firebase";
import { useState, useEffect } from "react";
import { useUserStore } from "@/store/useUserStore";
import { MarkerData } from "@/types/marker";
import { api } from "@/api";

//좋아요 카운트 구독
export const subscribeToLikeCount = (
  uid: string,
  callback: (count: number) => void
) => {
  const likeRef = ref(db, `marker/${uid}/like_count`);
  onValue(likeRef, (snapshot) => {
    callback(snapshot.val() || 0);
  });
  return () => off(likeRef);
};

export const useLikeCountRealtime = (uid: string, initialCount: number = 0) => {
  const [count, setCount] = useState<number | null>(null);

  useEffect(() => {
    if (!uid) return;

    // 초기값 설정
    setCount(initialCount);

    const likeRef = ref(db, `marker/${uid}/like_count`);
    const unsubscribe = onValue(likeRef, (snapshot) => {
      const newCount = snapshot.val();
      // null이나 undefined가 아닐 때만 업데이트
      if (newCount !== null && newCount !== undefined) {
        setCount(newCount);
      }
    });

    return () => unsubscribe();
  }, [uid, initialCount]);

  // count가 null이면 initialCount 반환
  return { data: count ?? initialCount };
};

//좋아요 토글
export const handleLikeToggle = async (uid: number, data: MarkerData) => {
  const userInfo = useUserStore.getState().getUser();

  if (data.user_uid === userInfo.uid) return;
  try {
    const likeRef = ref(db, `users/${userInfo.uid}/likes`);
    const snapshot = await get(likeRef);
    const currentLikes = snapshot.val() || [];

    let newLikes;
    const isLiked = currentLikes.includes(uid);
    if (isLiked) {
      newLikes = currentLikes.filter((id: number) => id !== uid);
    } else {
      newLikes = [...currentLikes, uid];
    }
    await set(likeRef, newLikes);

    // Firebase 좋아요 카운트 업데이트
    const markerRef = ref(db, `marker/${uid}`);
    await runTransaction(markerRef, (currentData) => {
      if (currentData === null) {
        return { like_count: isLiked ? 0 : 1 };
      }
      return {
        ...currentData,
        like_count: (currentData.like_count || 0) + (isLiked ? -1 : 1),
      };
    });

    // PHP API 좋아요 카운트 업데이트
    const response = await api.post("photo.php", {
      a: "updateLikeCount",
      markerId: uid,
      action: isLiked ? "decrease" : "increase",
    });

    if (response.data.status !== "success") {
      console.error("PHP API 좋아요 업데이트 실패:", response.data.message);
    }
  } catch (error) {
    console.error("좋아요 토글 중 오류 발생:", error);
  }
};
