import exifr from "exifr";

export interface ImageMetadata {
  dateTime?: string;
  make?: string;
  model?: string;
  orientation?: number;
  gpsLatitude?: number;
  gpsLongitude?: number;
  resolution?: {
    width: number;
    height: number;
  };
}

export const getImageMetadata = async (file: File): Promise<ImageMetadata> => {
  try {
    // 이미지 해상도 가져오기
    const img = new Image();
    const imgLoaded = new Promise((resolve, reject) => {
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });

    // EXIF 데이터 가져오기
    const [loadedImg, exif] = await Promise.all([imgLoaded, exifr.parse(file)]);

    const metadata: ImageMetadata = {
      resolution: {
        width: (loadedImg as HTMLImageElement).width,
        height: (loadedImg as HTMLImageElement).height,
      },
    };

    if (exif) {
      metadata.dateTime = exif.DateTimeOriginal || exif.DateTime;
      metadata.make = exif.Make;
      metadata.model = exif.Model;
      metadata.orientation = exif.Orientation;
      metadata.gpsLatitude = exif.latitude;
      metadata.gpsLongitude = exif.longitude;
    }

    URL.revokeObjectURL(img.src); // 메모리 정리
    return metadata;
  } catch (error) {
    console.warn("메타데이터 읽기 실패:", error);
    return {
      resolution: {
        width: 0,
        height: 0,
      },
    };
  }
};
