import { useState } from "react";

interface UseTabDragProps {
  initialTab: string;
  onTabChange: (newTab: string) => void;
}

interface UseTabDragReturn {
  touchStart: number;
  translateX: number;
  touchState: boolean;
  currentTab: string;
  handlers: {
    touchTabMoveStart: (e: React.TouchEvent) => void;
    touchTabMove: (e: React.TouchEvent) => void;
    touchTabEnd: (e: React.TouchEvent) => void;
  };
}

export const useTabDrag = ({
  initialTab,
  onTabChange,
}: UseTabDragProps): UseTabDragReturn => {
  const [touchStart, setTouchStart] = useState(0);
  const [translateX, setTranslateX] = useState(0);
  const [touchState, setTouchState] = useState(false);
  const [currentTab, setCurrentTab] = useState(initialTab);

  const touchTabMoveStart = (e: React.TouchEvent) => {
    setTouchStart(e.touches[0].clientX);
    setCurrentTab(initialTab);
    setTranslateX(initialTab === "myPhoto" ? 0 : -100);
  };

  const touchTabMove = (e: React.TouchEvent) => {
    setTouchState(true);
    const moveX = e.touches[0].clientX - touchStart;
    const moveBox = e.currentTarget as HTMLDivElement;
    const boxWidth = moveBox.offsetWidth;

    const movePercent = (moveX / boxWidth) * 100;

    let newTranslateX;
    if (currentTab === "myPhoto") {
      newTranslateX = Math.max(Math.min(movePercent, 0), -100);
    } else {
      newTranslateX = Math.max(Math.min(movePercent - 100, 0), -100);
    }

    setTranslateX(newTranslateX);
  };

  const touchTabEnd = (e: React.TouchEvent) => {
    setTouchState(false);

    if (currentTab === "myPhoto") {
      if (translateX < -50) {
        onTabChange("savedPhoto");
      }
    } else {
      if (translateX > -50) {
        onTabChange("myPhoto");
      }
    }
    setTranslateX(0);
  };

  return {
    touchStart,
    translateX,
    touchState,
    currentTab,
    handlers: {
      touchTabMoveStart,
      touchTabMove,
      touchTabEnd,
    },
  };
};
