import { useEffect } from "react";
import {
  Box,
  Button,
  Input,
  Stack,
  Text,
  Container,
  VStack,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../store/useUserStore";
import useBoolean from "../../hooks/useBoolean";
import SignInModal from "../auth/sigjnIn";
import { useForm } from "react-hook-form";
import { BackgroundImage, LoginBoxStyle } from "./style";
import introBgUrl from "../../assets/intro/intro_bg.svg?url";
import airplane from "../../assets/intro/airplane.svg?url";
import bus from "../../assets/intro/bus.svg?url";
import car from "../../assets/intro/car.svg?url";
import frame from "../../assets/intro/frame.svg?url";
import hot_air_balloon from "../../assets/intro/hot_air_balloon.svg?url";
import location from "../../assets/intro/location.svg?url";
import Logo from "../../assets/logo.svg?url";
import { googleHandler, onEmailLogin } from "@/api/auth/login";

function Login() {
  const { getUser } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (getUser()?.uid) {
      navigate("/");
    }
  }, [getUser]);

  const isSignInModal = useBoolean();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <LoginBoxStyle>
      <Box
        position="relative"
        minHeight="100vh"
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        overflow="hidden"
      >
        <Container zIndex="1" maxW="md">
          <BackgroundImage>
            <img src={introBgUrl} className="bg" alt="background" />
            <img src={airplane} className="airplane" alt="airplane" />
            <img src={bus} className="bus" alt="bus" />
            <img src={car} className="car" alt="car" />
            <img src={frame} className="frame" alt="frame" />
            <img src={hot_air_balloon} className="balloon" alt="balloon" />
            <img src={location} className="location" alt="location" />
          </BackgroundImage>
          <VStack
            spacing={8}
            p={8}
            bg="white"
            rounded="md"
            position="relative"
            className="login-box"
          >
            <img src={Logo} className="logo" alt="logo" />
            <Stack spacing={4} w="100%">
              <Button
                leftIcon={<FcGoogle />}
                colorScheme="purple"
                variant="outline"
                w="100%"
                onClick={() => googleHandler(navigate)}
              >
                구글 로그인
              </Button>
              <form
                onSubmit={handleSubmit((data) => onEmailLogin(data, navigate))}
              >
                <VStack spacing={4}>
                  <Input
                    placeholder="Email"
                    variant="filled"
                    {...register("email", {
                      required: "이메일을 입력해주세요",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "올바른 이메일 형식이 아닙니다",
                      },
                    })}
                  />
                  {errors.email && (
                    <Text color="red.500" fontSize="sm">
                      {errors.email.message}
                    </Text>
                  )}
                  <Input
                    placeholder="Password"
                    type="password"
                    variant="filled"
                    {...register("password", {
                      required: "비밀번호를 입력해주세요",
                      minLength: {
                        value: 6,
                        message: "비밀번호는 최소 6자 이상이어야 합니다",
                      },
                    })}
                  />
                  {errors.password && (
                    <Text color="red.500" fontSize="sm">
                      {errors.password.message}
                    </Text>
                  )}
                  <Button type="submit" colorScheme="purple" w="100%">
                    로그인
                  </Button>
                </VStack>
              </form>
              <Text>
                아직 회원이 아니신가요?{" "}
                <Button
                  colorScheme="purple"
                  variant="link"
                  onClick={() => isSignInModal.onTrue()}
                >
                  회원가입
                </Button>
              </Text>
            </Stack>
          </VStack>
        </Container>
        <SignInModal
          isOpen={isSignInModal.boolean}
          onClose={() => isSignInModal.onFalse()}
        />
      </Box>
    </LoginBoxStyle>
  );
}

export default Login;
