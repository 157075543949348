import {
  Button,
  Input,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { auth, db } from "../../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { createRandomNick } from "../../../utils/randomNick";
import { ref, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

type SignInModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

// 폼 데이터 타입 정의
type SignUpFormData = {
  email: string;
  password: string;
  passwordConfirm: string;
};

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SignUpFormData>();

  // 회원가입 처리 함수
  const onSubmit = async (data: SignUpFormData) => {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const user = result.user;
      const randomNick = createRandomNick();

      await set(ref(db, `users/${user.uid}`), {
        uid: user.uid,
        email: user.email,
        nick: randomNick,
        date_regis: new Date().getTime(),
      });

      onClose();
      navigate("/");
    } catch (error: any) {
      console.error("회원가입 오류:", error);
      if (error.code === "auth/email-already-in-use") {
        alert("이미 사용 중인 이메일입니다.");
      } else if (error.code === "auth/weak-password") {
        alert("비밀번호는 최소 6자리 이상이어야 합니다.");
      } else {
        alert("회원가입 중 오류가 발생했습니다.");
      }
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>회원가입</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
            <Input
              {...register("email", {
                required: "이메일을 입력해주세요",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "올바른 이메일 형식이 아닙니다",
                },
              })}
              placeholder="이메일"
              type="email"
              isInvalid={!!errors.email}
            />
            {errors.email && (
              <Text color="red.500">{errors.email.message}</Text>
            )}

            <Input
              {...register("password", {
                required: "비밀번호를 입력해주세요",
                minLength: {
                  value: 6,
                  message: "비밀번호는 최소 6자리 이상이어야 합니다",
                },
              })}
              placeholder="비밀번호"
              type="password"
              isInvalid={!!errors.password}
            />
            {errors.password && (
              <Text color="red.500">{errors.password.message}</Text>
            )}

            <Input
              {...register("passwordConfirm", {
                required: "비밀번호 확인을 입력해주세요",
                validate: (value) =>
                  value === getValues("password") ||
                  "비밀번호가 일치하지 않습니다",
              })}
              placeholder="비밀번호 확인"
              type="password"
              isInvalid={!!errors.passwordConfirm}
            />
            {errors.passwordConfirm && (
              <Text color="red.500">{errors.passwordConfirm.message}</Text>
            )}

            <Button type="submit" colorScheme="purple" w="100%">
              가입하기
            </Button>
          </VStack>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignInModal;
