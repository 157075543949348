import { ref, onValue, off, runTransaction, set, get } from "firebase/database";
import { db } from "@/firebase";
import { useState, useEffect } from "react";
import { useUserStore } from "@/store/useUserStore";
import { MarkerData } from "@/types/marker";
import { api } from "..";

//북마크 카운트 구독
export const subscribeToBookmarkCount = (
  uid: string,
  callback: (count: number) => void
) => {
  const bookmarkRef = ref(db, `marker/${uid}/bookmark_count`);
  onValue(bookmarkRef, (snapshot) => {
    callback(snapshot.val() || 0);
  });
  // 구독 해제 함수 반환
  return () => off(bookmarkRef);
};
export const useBookmarkCountRealtime = (
  uid: string,
  initialCount: number = 0
) => {
  const [count, setCount] = useState<number | null>(null);

  useEffect(() => {
    if (!uid) return;

    // 초기값 설정
    setCount(initialCount);

    const bookmarkRef = ref(db, `marker/${uid}/bookmark_count`);
    const unsubscribe = onValue(bookmarkRef, (snapshot) => {
      const newCount = snapshot.val();
      // null이나 undefined가 아닐 때만 업데이트
      if (newCount !== null && newCount !== undefined) {
        setCount(newCount);
      }
    });

    return () => unsubscribe();
  }, [uid, initialCount]);

  // count가 null이면 initialCount 반환
  return { data: count ?? initialCount };
};

//북마크 토글
export const handleBookmarkToggle = async (uid: number, data: MarkerData) => {
  const userInfo = useUserStore.getState().getUser();

  if (Number(data.user_id) === userInfo.id) return;
  try {
    const bookmarkRef = ref(db, `users/${userInfo.uid}/book_mark`);
    const snapshot = await get(bookmarkRef);
    const currentBookmarks = snapshot.val() || [];

    let newBookmarks;
    const isBookmarked = currentBookmarks.includes(uid);
    if (isBookmarked) {
      newBookmarks = currentBookmarks.filter((id: number) => id !== uid);
    } else {
      newBookmarks = [...currentBookmarks, uid];
    }
    await set(bookmarkRef, newBookmarks);

    // Firebase 북마크 카운트 업데이트
    const markerRef = ref(db, `marker/${uid}`);
    await runTransaction(markerRef, (currentData) => {
      if (currentData === null) {
        return { bookmark_count: isBookmarked ? 0 : 1 };
      }
      return {
        ...currentData,
        bookmark_count:
          (currentData.bookmark_count || 0) + (isBookmarked ? -1 : 1),
      };
    });

    // PHP API 북마크 카운트 업데이트
    const response = await api.post("photo.php", {
      a: "updateBookmarkCount",
      markerId: uid,
      action: isBookmarked ? "decrease" : "increase",
    });

    if (response.data.status !== "success") {
      console.error("PHP API 북마크 업데이트 실패:", response.data.message);
    }
  } catch (error) {
    console.error("북마크 토글 중 오류 발생:", error);
  }
};
