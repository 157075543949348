import { Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { AppInstallStyle } from "./style";
import { MdInstallDesktop } from "react-icons/md";

function PWAInstall() {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isInstallable, setIsInstallable] = useState(false);

  useEffect(() => {
    const handler = (e: Event) => {
      // beforeinstallprompt 이벤트 저장
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener("beforeinstallprompt", handler);

    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) return;

    // 설치 프롬프트 표시
    deferredPrompt.prompt();

    // 사용자의 응답을 기다림
    const { outcome } = await deferredPrompt.userChoice;
    console.log(`사용자 선택: ${outcome}`);

    // deferredPrompt 초기화
    setDeferredPrompt(null);
    setIsInstallable(false);
  };

  // 이미 설치되어 있는지 확인
  useEffect(() => {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      setIsInstallable(false);
    }
  }, []);

  if (!isInstallable) return null;

  return (
    <AppInstallStyle>
      <Button
        colorScheme={"purple"}
        onClick={handleInstallClick}
        className="install-button"
      >
        <MdInstallDesktop />
        <span>앱 설치하기</span>
      </Button>
    </AppInstallStyle>
  );
}

export default PWAInstall;
