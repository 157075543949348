import styled, { keyframes } from "styled-components";
import { mixColFlex, mixFlex } from "./mixIn";
import { colors } from "./colors";

// 위치 아이콘 드롭 애니메이션 키프레임 추가
export const dropAnimation = keyframes`
  0% {
    transform: translateY(-8px);
  }
  40% {
    transform: translateY(4px);
  }
  60% {
    transform: translateY(2px);
  }
  80% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const IconStyle = styled.div`
  ${mixFlex()};
  justify-content: flex-start;
  gap: 0.25rem;
  svg {
    font-size: 1.5rem;
  }
`;

export const CenterMarkerStyle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  ${mixColFlex("center", "center")};
  gap: 0.2rem;
  padding-bottom: 1.7rem;
  &.move {
    img {
      transform: translateY(-8px);
    }
  }
  img {
    width: auto;
    height: 2rem;
    color: ${colors.PURPLE_600};
    transition: all 0.2s ease;
    &.end {
      animation: ${dropAnimation} 0.3s ease;
    }
  }
  .shadow {
    width: 0.9rem;
    height: 0.4rem;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .center {
    width: 1px;
    height: 1px;
    position: fixed;
    left: 50%;
    top: 50%;
    background: #fff;
  }
`;
