import { api } from "..";
import { useQuery, keepPreviousData } from "@tanstack/react-query";

type GetLocationListParams = {
  northEast_lat: number;
  northEast_lng: number;
  southWest_lat: number;
  southWest_lng: number;
  startDate: string;
  endDate: string;
  userId: number;
};

export const getLocationList = async (params: GetLocationListParams) => {
  const { data } = await api.post(`photo.php`, {
    a: "getLocationList",
    ...params,
  });
  return data;
};

export const useGetLocationList = (params: GetLocationListParams) => {
  return useQuery({
    queryKey: ["locations", params],
    queryFn: () => getLocationList(params),
    enabled: !!params,
    placeholderData: keepPreviousData,
  });
};
