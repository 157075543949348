import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "..";

//내 사진 리스트 가져오기
export const getPhotoList = async (
  userId: number,
  page: number,
  limit: number
) => {
  return await api.post("photo.php", {
    a: "getMyPhoto",
    userId,
    page,
    limit,
  });
};
export const useGetPhotoList = (userId: number, limit: number) => {
  return useInfiniteQuery({
    queryKey: ["photo", userId],
    queryFn: ({ pageParam = 1 }) => getPhotoList(userId, pageParam, limit),
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.data.list.length < limit) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });
};

//즐겨찾기 가진 사진 리스트 가져오기
export const getBookmarkPhotoList = async (
  bookmarks: number[],
  page: number,
  limit: number
) => {
  return await api.post("photo.php", {
    a: "getBookmarkPhoto",
    bookmarks,
    page,
    limit,
  });
};
export const useGetBookmarkPhoto = (bookmarks: number[], limit: number) => {
  return useInfiniteQuery({
    queryKey: ["bookmarkPhoto", bookmarks],
    queryFn: ({ pageParam = 1 }) =>
      getBookmarkPhotoList(bookmarks, pageParam, limit),
    enabled: !!bookmarks,
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.data.list.length < limit) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
  });
};
