import { extractFilePathFromUrl } from "@/utils/urlToPath";
import { deleteObject, ref as sRef } from "firebase/storage";
import { storage } from "../../firebase";

export const deleteFileByUrl = async (url: string) => {
  const filePath = extractFilePathFromUrl(url); //url받아서 path로 변경
  const fileRef = sRef(storage, filePath);
  try {
    await deleteObject(fileRef);
  } catch (error) {
    console.error("Error deleting file:", error);
  }
}; //이미지 삭제
