import { Avatar, Button, Input, Switch, useDisclosure } from "@chakra-ui/react";
import { MapStyled } from "../style/componentStyle";
import { format, subYears } from "date-fns";
import MyphotoModal from "./modal/myPhotoList";
import { useUserStore } from "../store/useUserStore";
import { useDateStore } from "../store/useDateStore";
import { useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import { useState } from "react";

function BottomMenu({
  handleCurrentLocation,
  onSaveMode,
  saveMode,
  setSaveMode,
}) {
  const {
    isOpen: isMyPhotoOpen,
    onOpen: onOpenMyPhoto,
    onClose: onCloseMyPhoto,
  } = useDisclosure();
  const { getUser } = useUserStore();
  const userInfo = getUser();
  const { date, setDate } = useDateStore();
  const queryClient = useQueryClient();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  //과거 2년전까지 조회가능
  let minDate = format(subYears(new Date(), 2), "yyyy-MM");
  let maxDate = format(new Date(), "yyyy-MM");
  minDate = minDate.replace("%", "T");
  maxDate = maxDate.replace("%", "T");

  //리페칭
  const refetchLocations = async () => {
    await queryClient.invalidateQueries({ queryKey: ["locations"] });
  };

  // 날짜 선택 핸들러 수정
  const onDateSet = (selectedDate: Date) => {
    const formattedDate = format(selectedDate, "yyyy-MM");
    setDate(formattedDate);
    refetchLocations();
  };

  // 팝업에서 날짜 선택시
  const onPropsDateSet = (date: string) => {
    setDate(date);
    refetchLocations();
  };

  //전체/월간 선택 핸들러
  const [dateType, setDateType] = useState<string>("monthly");
  const onDateSwitch = (type: string) => {
    setDateType(type);
    if (type === "all") {
      setDate("");
    } else {
      setDate(format(new Date(), "yyyy-MM"));
    }
  };

  if (!userInfo) return null;
  return (
    <>
      <MapStyled>
        <div className="btn-container">
          <Avatar
            onClick={onOpenMyPhoto}
            name="Dan Abrahmov"
            src={userInfo.profileImage || ""}
          />
          <div className="date-select-box">
            <div className="date-switch-box">
              <span
                className={dateType === "all" ? "active" : ""}
                onClick={() => onDateSwitch("all")}
              >
                전체
              </span>
              <span
                className={dateType === "monthly" ? "active" : ""}
                onClick={() => onDateSwitch("monthly")}
              >
                월간
              </span>
            </div>
            {dateType === "all" ? (
              <div className="all-date">전체기간</div>
            ) : (
              <DatePicker
                selected={date ? new Date(date) : new Date()}
                onChange={(date: Date) => {
                  onDateSet(date);
                  setIsCalendarOpen(false);
                }}
                dateFormat="yyyy-MM"
                showMonthYearPicker
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                locale={ko}
                className="custom-datepicker"
                onFocus={() => setSaveMode(false)}
                onKeyDown={(e) => e.preventDefault()}
                open={isCalendarOpen}
                onClickOutside={() => setIsCalendarOpen(false)}
                onInputClick={() => setIsCalendarOpen(!isCalendarOpen)}
              />
            )}
          </div>
          <Button
            boxShadow="lg"
            className="btn-move-currnet"
            fontSize={14}
            onClick={handleCurrentLocation}
          >
            현재위치
          </Button>
          <Button
            boxShadow="lg"
            colorScheme="purple"
            fontSize={14}
            onClick={onSaveMode}
          >
            {saveMode ? "위치탐색" : "위치저장"}
          </Button>
        </div>
      </MapStyled>

      <MyphotoModal
        onDateSet={onPropsDateSet}
        userInfo={userInfo}
        isMyPhotoOpen={isMyPhotoOpen}
        onCloseMyPhoto={onCloseMyPhoto}
      />
    </>
  );
}

export default BottomMenu;
