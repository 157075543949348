import { Box } from "@chakra-ui/react";
import styled, { keyframes } from "styled-components";

// 비행기 애니메이션 키프레임 추가
const flyAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(100%, 100%);
  }
  20% {
    opacity: 1;
    transform: translate(-20%, -20%);
  }
  to {
    opacity: 1;
    transform: translate(-20%, -20%);
  }
`;

// 풍선 애니메이션 키프레임 추가
const floatAnimation = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  50% {
    transform: translateY(0);
    opacity: 1;
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

// 둥둥 떠다니는 애니메이션 추가
const float = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
`;

const locationFloat = keyframes`
  0% {
    transform: translate(-50%,0);
  }
  50% {
    transform: translate(-50%, -10px);
  }
  100% {
    transform: translate(-50%, 0);
  }
`;

// 위치 아이콘 드롭 애니메이션 키프레임 추가
export const dropAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -100%);
  }

  60% {
    opacity: 1;
    transform: translate(-50%, 20%);
  }
  80% {
    opacity: 1;
    transform: translate(-50%, -5%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

const fadeUp = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, 50%);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
`;

// 버스 애니메이션 키프레임 추가
const busAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
    opacity: 1;
    transform: translateX(-350%);
  }
  90% {
    opacity: 0;
    transform: translateX(-400%);
  }
  100% {
    opacity: 0;
    transform: translateX(-420%);
  }
`;

const carAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  20% {
    opacity: 1;
    transform: translateX(0);
  }
  80% {
    opacity: 1;
    transform: translateX(-950%);
  }
  90% {
    opacity: 0;
    transform: translateX(-1050%);
  }
  100% {
    opacity: 0;
    transform: translateX(-1050%);
  }
`;

export const LoginBoxStyle = styled.div`
  position: relative;
  .login-box {
    margin-top: 16rem;
  }
  .logo {
    width: 12rem;
  }
`;

export const BackgroundImage = styled(Box)`
  position: absolute;
  z-index: 20;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 18rem;
  height: 18rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 0;
    height: 0;
    position: absolute;
  }
  .bg {
    width: auto;
    height: 100%;
  }
  .airplane {
    width: 5rem;
    height: auto;
    right: 0;
    top: 4.5rem;
    animation: ${flyAnimation} 10s 0.2s ease-out infinite; // 애니메이션 적용
  }
  .balloon {
    width: 3rem;
    height: auto;
    left: 1.5rem;
    top: 4rem;
    animation: ${floatAnimation} 2s ease-out forwards,
      ${float} 3s ease-in-out infinite 2s; // 초기 애니메이션 후 무한 반복
  }
  .frame {
    width: 10rem;
    height: auto;
    left: calc(50% + 0.5rem);
    top: 5rem;
    transform: translateX(-50%);
    animation: ${fadeUp} 0.5s ease-out forwards;
  }
  .location {
    width: 6rem;
    height: auto;
    left: calc(50% + 0.5rem);
    top: 4.5rem;
    transform: translateX(-50%);
    opacity: 0;
    animation: ${dropAnimation} 1s cubic-bezier(0.3, 0, 0.3, 1) 0.5s forwards,
      ${locationFloat} 3s ease-in-out infinite 1.5s;
  }
  .bus {
    width: 5rem;
    height: auto;
    right: 0;
    bottom: 1.5rem;
    animation: ${busAnimation} 8s 0.5s linear infinite;
    transform: scaleX(-1);
  }
  .car {
    width: 2rem;
    height: auto;
    right: 0;
    bottom: 1.5rem;
    animation: ${carAnimation} 6s linear infinite;
    transform: scaleX(-1);
  }
`;
