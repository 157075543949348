import { css } from "styled-components";

export const mixFlex = (justify = "center", align = "center") => css`
  display: flex;
  justify-content: ${justify};
  align-items: ${align};
`;

export const mixColFlex = (justify = "flex-start", align = "center") => css`
  display: flex;
  flex-direction: column;
  justify-content: ${justify};
  align-items: ${align};
`;

export const mixGrid = (ea = 1, width = "1fr") => css`
  display: grid;
  grid-template-columns: repeat(${ea}, ${width});
`;

export const mixFadeIn = (duration = "0.5s") => css`
  animation: fadeIn ${duration} forwards;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const mixPosCen = (position = "absolute") => css`
  position: ${position};
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

//말줄임
export const mixEllipsis = (clamp = 1) => css`
  overflow: hidden;
  text-overflow: ellipsis;

  ${clamp === 1
    ? css`
        white-space: nowrap;
      `
    : css`
        display: -webkit-box;
        -webkit-line-clamp: ${clamp};
        -webkit-box-orient: vertical;
        white-space: normal;
      `}
`;

//전체화면 고정
export const mixFixFull = () => css`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;

//기본 카드박스
export const mixCardBox = (raius = "0.75rem") => css`
  border-radius: ${raius};
  background-color: #fff;
  padding: 1.25rem;
`;

//스크롤박스
export const mixScrollBox = (maxHeight = "90vh", width = "8px") => css`
  max-height: ${maxHeight};
  overflow: auto;
  &::-webkit-scrollbar {
    width: ${width};
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
  }
`;
